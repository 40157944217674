exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-issues-js": () => import("./../../../src/pages/internet-issues.js" /* webpackChunkName: "component---src-pages-internet-issues-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-site-privacy-js": () => import("./../../../src/pages/site/privacy.js" /* webpackChunkName: "component---src-pages-site-privacy-js" */),
  "component---src-pages-site-terms-js": () => import("./../../../src/pages/site/terms.js" /* webpackChunkName: "component---src-pages-site-terms-js" */),
  "component---src-pages-university-js": () => import("./../../../src/pages/university.js" /* webpackChunkName: "component---src-pages-university-js" */),
  "component---src-pages-university-success-js": () => import("./../../../src/pages/university-success.js" /* webpackChunkName: "component---src-pages-university-success-js" */)
}

