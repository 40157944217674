import React from "react";
import Color from "color";
import hexToRgba from "hex-to-rgba";
import * as BaseColor from "./BaseColors.js";
import { fontWeights } from "./fontWeights.js";
import screenSizes from "./devices/screenSizes.js";

// CanvasRenderingContext2D.prototype.roundRect = function (
//   x,
//   y,
//   width,
//   height,
//   radius
// ) {
//   if (width < 2 * radius) radius = width / 2;
//   if (height < 2 * radius) radius = height / 2;
//   this.beginPath();
//   this.moveTo(x + radius, y);
//   this.arcTo(x + width, y, x + width, y + height, radius);
//   this.arcTo(x + width, y + height, x, y + height, radius);
//   this.arcTo(x, y + height, x, y, radius);
//   this.arcTo(x, y, x + width, y, radius);
//   this.closePath();
//   return this;
// };

let colors = { 
  colorful: {
    primary: BaseColor.colorful.primary,
    secondary: BaseColor.colorful.secondary,
    disabled: BaseColor.colorful.disabled
  },

  bg: {},
  text: {},
  button: {
    primary: {},
    success: {},
    error: {},
  },
  boxShadow: {
    neumo: {},
    primary: {},
    green:{}
  },

  div: {
    secondary:{
    classes:{},
    error:{},
    success:{},
    alert:{}
  }
},

  border:{},

  card: {},

  machine: {},

  autocomplete: {
    border: {}
  },
  input:{},
  divider:{},

  switch: {
    border: {},
    led: {
      on: {},
      off: {},
      border: {}
    }
  },

  progressBar:{}
};

function margin(exp) {
  return Math.pow(2, exp);
}

let effects = {
  neumo: {}
};

let styles = {
  autocomplete: {
    border: {},
    icon: {}
  },
  div: {},
  boxShadow: {

  },

  card: {
    media: {}
  },

  drawer:{},

};

let fonts = {
  title: {
    xxl:{},
    xl: {},
    l: {},
    m: {},
    s: {},
    xsi: {},
    xs: {}
  },
  body: {
    xl: {},
    l: {},
    m: {},
    s: {},
    xs: {}
  },
  weight:fontWeights,
};

let breakpoints = screenSizes;

//FONTS
// fonts.title.xl.size = '3.6rem';
// fonts.title.l.size = '2.9rem';
// fonts.title.m.size = '2.3rem';
// fonts.title.s.size = '1.6rem';
// fonts.title.xs.size = '1.25rem';
// fonts.body.m.size = '1.5rem';
fonts.title.xxl.size = '4rem';
fonts.title.xl.size = '2.25rem';
fonts.title.l.size = '1.8125rem';
fonts.title.m.size = '1.3375rem';
fonts.title.s.size = '1rem';
fonts.title.xsi.size = '0.9rem';
fonts.title.xs.size = '0.78125rem';
fonts.body.s.size = '0.875rem';
fonts.body.m.size = '0.9375rem';

fonts.title.xl.letterSpacing = '0.01rem';
fonts.title.l.letterSpacing = '0rem';
fonts.title.m.letterSpacing = '0rem';
fonts.title.s.letterSpacing = '0rem';
fonts.title.xsi.letterSpacing = '0rem';
fonts.title.xs.letterSpacing = '0rem';

//DEFAULT EFFECTS
effects.neumo.blurLength = 10;
effects.neumo.spread = 5;
effects.neumo.borderRadius = 15;

//BACKGROUND
colors.bg.default = BaseColor.neumo.default;
colors.bg.blue = BaseColor.classes.default;

styles.boxShadow.paper = "0px 4px 24px rgb(88 102 126 / 10%), 0px 1px 2px rgb(88 102 126 / 14%)";

//DIVIDER
colors.divider = BaseColor.neumo.ultraLight;

//DIV
colors.div.secondary.classes.bg = hexToRgba(BaseColor.classes.default,0.1);
// colors.div.secondary.success.bg  = hexToRgba(BaseColor.success.default,0.1);
colors.div.secondary.error.bg = BaseColor.error.light;
colors.div.secondary.success.bg = BaseColor.success.ultraLight;
colors.div.secondary.alert.bg = BaseColor.alert.light;

colors.div.secondary.classes.light = hexToRgba(BaseColor.white.default,0.7)
colors.div.secondary.classes.contrast = hexToRgba(BaseColor.classes.default,0.1)

colors.div.secondary.success.light = hexToRgba(BaseColor.white.default,0.7)
colors.div.secondary.success.contrast = hexToRgba(BaseColor.success.default,0.1)

colors.div.secondary.error.light = hexToRgba(BaseColor.white.default,0.7)
colors.div.secondary.error.contrast = hexToRgba(BaseColor.error.default,0.1)

colors.input.default = BaseColor.neumo.input;
colors.input.focused = BaseColor.white.default;

colors.div.default = BaseColor.neumo.default;
styles.div.borderRadius = effects.neumo.borderRadius;

//TEXT
colors.text.default = BaseColor.neumo.text;
colors.text.white = BaseColor.white.default;
colors.text.purple = BaseColor.purple.default;
colors.text.darkPurple = BaseColor.purple.dark;
colors.text.darkerPurple = BaseColor.purple.darker;
colors.text.secondary = BaseColor.purple.light;
colors.text.light =  BaseColor.neumo.lightText;
colors.text.ultraLight =  BaseColor.neumo.ultraLight;
colors.text.medium =  BaseColor.neumo.medium;
colors.text.error = BaseColor.error.default;
colors.text.alert = BaseColor.alert.default;
colors.text.success = BaseColor.success.default;
colors.text.classes = BaseColor.classes.default;


//BUTTON
colors.button.default = BaseColor.neumo.default;
colors.button.disabled = BaseColor.neumo.disabled;
colors.button.hover = BaseColor.neumo.hover;

colors.progressBar = "linear-gradient(135deg, " +
BaseColor.greenGradient.left +
" 30%, " +
BaseColor.greenGradient.right +
" 90%)";

colors.boxShadow.neumo.light = hexToRgba(
  Color(BaseColor.neumo.default)
    .rotate(-6)
    .lighten(0.9)
    .hex()
);
colors.boxShadow.neumo.contrast = Color(BaseColor.neumo.default)
  .rotate(4)
  .desaturate(0.5)
  .darken(0.1)
  .hex();

  colors.button.primary.left = BaseColor.gradient.left;
  colors.button.primary.right = BaseColor.gradient.right;

colors.button.primary.gradient =
  "linear-gradient(135deg, " +
   colors.button.primary.left +
  " 30%, " +
  colors.button.primary.right +
  " 90%)";
colors.button.primary.average = Color([
  (Color( colors.button.primary.left).color[0] +
    Color(colors.button.primary.right).color[0]) /
    2,
  (Color( colors.button.primary.left).color[1] +
    Color(colors.button.primary.right).color[1]) /
    2,
  (Color( colors.button.primary.left).color[2] +
    Color(colors.button.primary.right).color[2]) /
    2
]);
colors.boxShadow.primary.light = hexToRgba(
  colors.button.primary.average.hex(),
  0.4
);
colors.boxShadow.primary.contrast = hexToRgba(
  colors.button.primary.average.hex(),
  0.6
);
colors.boxShadow.primary.insetLight = hexToRgba(BaseColor.white.default, 0.4);
colors.boxShadow.primary.insetContrast = hexToRgba(
  colors.button.primary.average.darken(0.05).hex(),
  1
);

colors.boxShadow.green.light = hexToRgba(
  Color(BaseColor.greenGradient.left).hex(),
  0.4
);

colors.boxShadow.green.contrast = hexToRgba(
  Color(BaseColor.greenGradient.right).hex(),
  0.6
);

colors.button.success.left = BaseColor.success.default;
colors.button.success.right = BaseColor.success.light;
colors.button.success.gradient = `linear-gradient(217deg, ${colors.button.success.left}, ${colors.button.success.right})`;

colors.button.error.left = BaseColor.error.default;
colors.button.error.right = BaseColor.error.light;
colors.button.error.gradient = `linear-gradient(217deg, ${colors.button.error.left}, ${colors.button.error.right})`;

//BORDER
colors.border.left = BaseColor.borderGradient.left;
colors.border.right = BaseColor.borderGradient.right;
colors.border.default = BaseColor.borderGradient.default;
//colors.border.linearGradient = BaseColor.borderGradient.right;

//AUTOCOMPLETE
colors.autocomplete.border.linearGradient =  "linear-gradient(179deg, " + colors.border.left + " 10%, " + colors.border.right + " 80%)";
//E9EBED
// colors.autocomplete.border.linearGradient =
//   "linear-gradient(179deg, " +
//   hexToRgba(BaseColor.white.default,0.7) +
//   " 10%, " +
//   hexToRgba(BaseColor.neumo.text, 0.05) +
//   " 80%)";

// colors.autocomplete.border.linearGradient =
//   "linear-gradient(179deg, " +
//   "#FFFFFF" +
//   " 10%, " +
//   "#E9EBED" +
//   " 80%)";


//SWITCH
colors.switch.border.linearGradient =
  "linear-gradient(90deg, " +
  BaseColor.white.default +
  " 10%, " +
  hexToRgba(BaseColor.neumo.text, 0.4) +
  " 80%)";
colors.switch.led.border.linearGradient =
  "linear-gradient(-90deg, " +
  BaseColor.white.default +
  " 10%, " +
  hexToRgba(BaseColor.neumo.text, 0.4) +
  " 80%)";
colors.switch.led.on.bg = BaseColor.led.green;

//CARD
colors.card.default = BaseColor.neumo.default;
styles.card.borderRadius = effects.neumo.borderRadius;
styles.card.maxWidth = 300;
styles.card.media.height = 92;

//effects.neumo.borderRadius = 10;
//effects.neumo.lightBlurColor =Color(colors.bg.default).rotate(-6).lighten(0.05).alpha(0.2).hex();

//contrastColorPrimary: 'rgba('+average[0]+','+average[1]+','+average[2]+','+0.5+')',

export const theme = {
  breakpoints: breakpoints,
  colors: colors,
  effects: effects,
  styles: styles,
  margin,
  fonts: fonts,
};

export default theme;
