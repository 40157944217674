import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ParallaxProvider } from 'react-scroll-parallax';

import StyledComponentsThemeProvider from "./ThemeProvider";


export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
 

      </Helmet>

 
      <StyledComponentsThemeProvider>
        {/* <TranslationProvider> */}
          <ParallaxProvider>
          {props.children}
          </ParallaxProvider>
        {/* </TranslationProvider> */}
      </StyledComponentsThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;