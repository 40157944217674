
export const neumo = {
    // default: '#F1F3F6',
    default: '#F4F5F9',
    disabled: '#E9EBEE',
    hover:'#ebedf0',
    text: '#444445',
    medium: "#716a6a",
    lightText: '#c2c2c2',
    ultraLight: "#DADADA",
    input: "#EEEFF3"
}

export const led = {
    green: '#00ebb8',
}

// export const gradient = {
//     left: '#A1CCFF',
//     right: '#A7C0FF',
// }

// export const gradient = {
//     left: '#FFB3B3',
//     right: '#FECAB4',
// }

export const gradient = {
    left: '#B1B5FE',
    right: '#99ceff'
}

export const borderGradient = {
    left: '#FFFFFF',
    //right: '#E9EBED',
    right: '#E9EBED',
    default: '#DEDFE0'
    
}

export const greenGradient = {
    left: '#3bebc0',
    right: '#1feae4',
}

export const white = {
    default: '#FFFFFF',
}

export const black = {
    default: '#000000',
}

export const purple = {
    default:'#8690E4',
    light:'#9A81FF',
    dark:'#6363ff',
    darker:'#4949ba'
}

export const success = {
    default: '#00D4A1',
    light: '#9AFFED',
    ultraLight: '#E0F5F4',
}

export const error = {
    default: '#ff7878',
    light: '#ffbaba',
}

export const alert = {
    default: '#FBA12E',
    light: '#fff0de',
}

export const classes = {
    default: '#777bfe',
}

export const colorful = {
    primary: '#9494ff',
    secondary: "#9494ff", 
    disabled: '#ceceed'
}