const screenSizes = {
    phone:{min:320,max:599},
    tablet:{min:600,max:899},
    tabletLandscape:{min:900,max:1199},
    desktop:{min:1200,max:1459},
    bigDesktop:{min:1460,max:10000},
    mobile:{},
    notMobile:{},
    xxs: 320,
    xs: 600,
    sm: 900,
    md: 1200,
    lg: 1536,
}

const components = {
    appbar: 52,
    sidemenu:155,
    IconsSidemenu:60,
    datasetToolSideBar:240,
    contentMax: screenSizes.tabletLandscape.max,
}

screenSizes.mobile.max = screenSizes.tablet.max;
screenSizes.notMobile.min = screenSizes.tabletLandscape.min;

components.overlapSidemenuMin = components.sidemenu+components.contentMax;
components.contentMaxWithMenuIcon = components.contentMax + components.appbar*2;

screenSizes.components = components;

// export default screenSizes;

module.exports = screenSizes;