import React from "react";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import theme from "styles/theme";

import GlobalStyles from "styles/Global";

function ThemeProvider(props) {
  return (
    <StyledComponentThemeProvider theme={theme}>
      {props.children}
      <GlobalStyles />
    </StyledComponentThemeProvider>
  );
}

export default ThemeProvider;
