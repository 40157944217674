import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  /* @import url('<https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap>'); */
  
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body {
    height: 100%;
    scroll-behavior: smooth;
  }
  body {

    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  
    /* overflow-x: hidden; */
  }
  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
`;

